<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2"
      v-b-modal.sendMailOblige
      @click="getTemplateMail()"
    >
      <font-awesome-icon icon="envelope"
    /></b-button>
    <!-- Modal pour ajouter les emails -->
    <b-modal
      id="sendMailOblige"
      ref="sendMailOblige"
      :title="'Email à envoyer :  ' + lotsToSend[index]"
      size="lg"
      @hidden="resetModalMailing"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <!-- <form @submit.prevent="getTemplateMail()"> -->
      <form>
        <div class="d-flex justify-content-center">
          <b-button
            class="button-valide-style mr-4"
            @click.prevent.stop="sendMail"
            v-if="index < lotsToSend.length && index >= 0"
            :disabled="computedDisabledSend"
          >
            <span>
              Envoyer
              <div class="loading ml-2"></div>
            </span>
          </b-button>
        </div>

        <div class="mail-modal-container">
          <div>
            <step-component :lot="lotsToSend[index]" />
          </div>
        </div>

        <div class="actionModel d-flex justify-content-center">
          <b-button
            class="button-valide-style ml-4"
            type="submit"
            v-if="index < lotsToSend.length && index > 0"
            :disabled="loaderAcheteurMailObligee || loaderVendeurMailObligee"
            @click="getTemplateMailPrecedent()"
          >
            <span>
              Précédent
              <div class="loading ml-2"></div>
            </span>
          </b-button>
          <span
            v-for="(i, indexlot) in lotsToSend"
            :key="'lotIndex' + i"
            class="circleStep"
            :class="{ circleStepActive: indexlot == index }"
          ></span>
          <b-button
            class="button-valide-style ml-4"
            type="submit"
            v-if="index < lotsToSend.length - 1 && index >= 0"
            :disabled="loaderAcheteurMailObligee || loaderVendeurMailObligee"
            @click="getTemplateMail()"
          >
            <span>
              Suivant
              <div class="loading ml-2"></div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import stepComponent from './mailComponent/stepComponent.vue';
export default {
  components: { stepComponent },
  data() {
    return {
      loading: false,
      emailEnvoyer: [],
      index: -1
    };
  },
  props: {
    lotsToSend: { required: true },
    clientToSend: { required: true }
  },
  computed: {
    ...mapGetters([
      'getMailLoadingVertigo',
      'getSuccessMailsSent',
      'getErrorMailsSent',
      'getOtherErrorsMail',
      'MailDataAcheteurObligee',
      'MailDataVendeurObligee',
      'loaderVendeurMailObligee',
      'loaderAcheteurMailObligee',
      'arrayMsgSendMailObligee'
    ]),
    computedDisabledSend() {
      if (
        this.MailDataAcheteurObligee == null &&
        this.MailDataVendeurObligee == null
      ) {
        return true;
      }
      if (
        this.MailDataAcheteurObligee != null &&
        this.MailDataAcheteurObligee.vendeur_client == true
      ) {
        if (
          this.MailDataAcheteurObligee.title == null ||
          this.MailDataAcheteurObligee.title == '' ||
          this.MailDataAcheteurObligee.mail == null ||
          this.MailDataAcheteurObligee.mail == ''
        ) {
          return true;
        }
      }
      if (
        this.MailDataVendeurObligee != null &&
        this.MailDataVendeurObligee.vendeur_client == true
      ) {
        if (
          this.MailDataVendeurObligee.title == null ||
          this.MailDataVendeurObligee.title == '' ||
          this.MailDataVendeurObligee.mail == null ||
          this.MailDataVendeurObligee.mail == ''
        ) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'sendMailVertigo',
      'getMailDataVAObligee',
      'sendMailDataVAObligee'
    ]),
    getTemplateMail() {
      this.index = this.index + 1;
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'vendeur'
      });
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'client'
      });
    },
    getTemplateMailPrecedent() {
      this.index = this.index - 1;
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'vendeur'
      });
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'client'
      });
    },
    sendMail() {
      if (
        this.MailDataAcheteurObligee != null &&
        this.MailDataAcheteurObligee.vendeur_client == true
      ) {
        this.sendMailDataVAObligee(this.MailDataAcheteurObligee);
      }
      if (
        this.MailDataVendeurObligee != null &&
        this.MailDataVendeurObligee.vendeur_client == true
      ) {
        this.sendMailDataVAObligee(this.MailDataVendeurObligee);
      }
      if (this.lotsToSend.length === this.index + 1) {
        this.$refs['sendMailOblige'].hide();
      } else {
        this.getTemplateMail();
      }
    },
    resetModalMailing() {
      this.index = -1;
      this.emailEnvoyer = [];
    },
    addNewEmail() {
      this.emailEnvoyer.push({ email: null });
    },
    deleteEmail(index) {
      this.emailEnvoyer.splice(index, 1);
    }
  },
  watch: {
    arrayMsgSendMailObligee() {
      this.arrayMsgSendMailObligee.forEach(item => {
        if (item.vu == false) {
          this.flashMessage.show({
            status: item.type,
            title: 'LOT : ' + item.lot,
            clickable: true,
            time: 0,
            blockClass: 'custom-block-class',
            contentClass: 'custom-content-class',
            message: item.msg
          });
          item.vu = true;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.mail-icon-commercial-lot {
  font-size: 11.5px;
  margin-right: 4px;
  color: #8d8cb7;
  &:hover {
    color: #4f4e93;
  }
}
.mail-modal-container {
  min-height: calc(100vh - 137px);
  overflow-y: scroll;
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.tox-notifications-container {
  display: none !important;
}
</style>
